/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
// Pages
import Login from './Pages/Login';
import Home from './Pages/Home';
import ProductView from './Pages/ProductView';
import ProductEdit from './Pages/ProductEdit';
import ProductAdd from './Pages/ProductAdd';
import TypesPage from './Pages/TypesPage';
import Users from './Pages/Users';
import Navbar from './Components/Navbar';
import CustomRoute from './Components/CustomRoute';
import { autoLogin } from './functions/autoLogin';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginByIdAction } from './Redux/actions/userAction';
import History from './Pages/History';
import LocationPage from './Pages/LocationPage';
function App() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.user);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const loginOrRedirect = async () => {
    try {
      await autoLogin(dispatch, loginByIdAction);
      if (!loggedIn && location.pathname !== '/') {
        setRedirectUrl('/');
        localStorage.setItem('preLoginUrl', location.pathname);
      } else if (loggedIn) {
        const preLoginUrl = localStorage.getItem('preLoginUrl');
        if (preLoginUrl) {
          setRedirectUrl(preLoginUrl);
          localStorage.removeItem('preLoginUrl');
        }
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  useEffect(() => {
    loginOrRedirect();
    setShowAdminMenu(loggedIn);
  }, [location, loggedIn]);

  useEffect(() => {
    if (redirectUrl) {
      history.push(redirectUrl);
      setRedirectUrl(null);
    }
  }, [redirectUrl]);
  return (
    <div className="App">
      <Navbar showAdminMenu={showAdminMenu} setShowAdminMenu={setShowAdminMenu} />
      <Switch>
        <Route path="/" exact render={() => <Login />} />
        <Route path="/home" render={() => <Home />} />
        <CustomRoute path="/product_view/" render={() => <ProductView />} />
        <CustomRoute path="/location" render={() => <LocationPage />} />
        <CustomRoute path="/product_edit/" render={() => <ProductEdit />} />
        <CustomRoute path="/product_add" render={() => <ProductAdd />} />
        <CustomRoute path="/history" render={() => <History />} />
        <CustomRoute path="/all_types" render={() => <TypesPage />} />
        <CustomRoute path="/all_users" render={() => <Users />} />
      </Switch>
    </div>
  );
}

export default App;
