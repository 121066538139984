import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ModalWrapper } from '../StyledComps/styledComponents';
import { Btn } from '../Components/Button';
import jsQR from 'jsqr';
import { useHistory } from 'react-router-dom';

const MediaDiv = styled.div`
  width: 100%;
  height: 255px;
  position: relative;
  margin: 1rem 0;
  background: black;

  #interactive.viewport {
    width: 100%;
    height: 100%;
  }

  #interactive.viewport video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 150px;
  }
`;

const StyledP = styled.p`
  margin: auto;
  font-size: 18px;
`;

export default function QRCodeModal({
  barcodeModalHandler,
  onDetected,
  result,
  setResult,
}) {
  const videoRef = useRef(null);
  const canvasRef = useRef(document.createElement('canvas'));
  const [lastScannedCode, setLastScannedCode] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'environment' } })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((err) => {
        console.error('Error accessing the camera: ', err);
      });

    const scanInterval = setInterval(() => {
      if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
        canvas.height = videoRef.current.videoHeight;
        canvas.width = videoRef.current.videoWidth;
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const qrCode = jsQR(imageData.data, canvas.width, canvas.height);

        if (qrCode && qrCode.data !== lastScannedCode) {
          setResult(qrCode.data);
          setLastScannedCode(qrCode.data);
          if (qrCode.data.startsWith('http')) {
            const path = new URL(qrCode.data).pathname; // Extract the path from the URL
            history.push(path);
          } else {
            onDetected(qrCode.data);
          }
        }
      }
    }, 1000);

    return () => {
      clearInterval(scanInterval);
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }
      // Do not clear the last scanned code here to preserve the value
    };
  }, [onDetected, lastScannedCode, setResult]);

  return (
    <ModalWrapper>
      <div className="inner">
        <h1>QR Code</h1>
        <div className="line" />
        <StyledP>{result ? result : 'Scanning...'}</StyledP>
        <MediaDiv>
          <div id="interactive" className="viewport">
            <video ref={videoRef} playsInline />
          </div>
        </MediaDiv>
        <Btn handler={barcodeModalHandler}>Done</Btn>
      </div>
    </ModalWrapper>
  );
}
