import axios from "axios";

export const fetchEvents = async () => {
    return axios
        .get("api/events/all_events")
        .then((res) => {
            if (res.status !== 200) {
                return { status: false, msg: "nepavyko susisiekti su serveriu" };
            }
            if (res.data.msg !== "success") {
                return { status: false, msg: "no events" };
            } else {
                return res.data.data;
            }
        })
        .catch((err) => {
            console.log(err);
            return { status: false, msg: "nepavyko susisiekti su serveriu" };
        });
};
export const deleteEvents = async (type) => {
    return axios
        .delete(`api/events/delete_events/${type}`)
        .then((res) => {
            if (res.status !== 200) {
                return { status: false, msg: "nepavyko susisiekti su serveriu" };
            }
            if (res.data.msg !== "success") {
                console.log("heree1");
                return { status: false, msg: "no events" };
            } else {
                console.log("heree2");
                console.log(res.data.msg);
                return res.data.msg;
            }
        })
        .catch((err) => {
            console.log(err);
            return { status: false, msg: "nepavyko susisiekti su serveriu" };
        });
};
