import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ModalWrapper, StyledForm } from '../StyledComps/styledComponents';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BtnGreen } from '../Components/Button';
import axios from 'axios';

const AddTypeModal = ({
  typeInputs,
  typeInputHandler,
  typeImgInputHandler,
  submitType,
  closeModalhandler,
  typeInputError,
  isEditingMode,
}) => {
  const [customText, setCustomtext] = useState('No file chosen...');

  const [previewSource, setPreviewSource] = useState(null);
  const uploadFilRef = useRef();
  const openFileInput = () => {
    uploadFilRef.current.click();
  };
  const fileChangeHandler = () => {
    if (uploadFilRef.current.value) {
      setCustomtext(`${uploadFilRef.current.files[0].name}`);
      const reader = new FileReader();
      reader.readAsDataURL(uploadFilRef.current.files[0]);
      reader.onloadend = () => {
        setPreviewSource(reader.result);
      };
    }
  };
  const uploadBtnHandler = () => {
    if (!previewSource) return;
    uploadImage(previewSource);
  };
  const uploadImage = async (file) => {
    try {
      const response = await axios.post('/api/upload_images', {
        data: file,
        upload_preset: 'stone_types',
      });
      const data = await response;
      typeImgInputHandler(data.data.data);
      setCustomtext('File uploaded');
    } catch (error) {
      setCustomtext("Can't upload file");
    }
  };
  const isEditing = isEditingMode;

  return (
    <ModalWrapper className="outer" onClick={closeModalhandler}>
      <div className="inner">
        <h1> {isEditing ? 'Update Type' : 'Add New Type'}</h1>
        <div className="line"></div>
        {typeInputError && <StyledSmall>{typeInputError}</StyledSmall>}
        <StyledForm>
          <div className="form-row">
            <div className="form-control">
              <label htmlFor="type_name">Type name</label>
              <input
                type="text"
                id="name"
                placeholder="Enter type name"
                value={typeInputs.name}
                onChange={typeInputHandler}
              />
            </div>
            <div className="form-control">
              <label htmlFor="type_id">Type ID</label>
              <input
                type="text"
                id="type_id"
                readonly
                disabled={true}
                placeholder="Enter type ID"
                value={typeInputs.type_id}
                onChange={typeInputHandler}
              />
            </div>
          </div>
        </StyledForm>
        <FileCont>
          <div className="input_cont">
            <input
              type="file"
              id="add_img"
              aaccept="image/*"
              hidden="hidden"
              onChange={fileChangeHandler}
              ref={uploadFilRef}
            />
            <button type="button" id="file-input-btn" onClick={openFileInput}>
              Select File
            </button>
          </div>
          <button type="button" id="upload_file_btn" onClick={uploadBtnHandler}>
            <AiOutlineCloudUpload /> &nbsp; Upload
          </button>
        </FileCont>
        <CustomText>{customText}</CustomText>
        <BtnGreen handler={submitType}>
          {' '}
          {isEditing ? 'Update Type' : 'Store New Type'}
        </BtnGreen>
      </div>
    </ModalWrapper>
  );
};

const FileCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    cursor: pointer;
    padding: 0.2rem 1rem;
    background-color: transparent;
    border-radius: 10px;
    border: solid 1px #32394d;
    color: #32394d;
    display: inline-flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    margin: 0 0.5rem 0.5rem 0;
    outline-width: 0;
    transition: all 0.2s ease;
    &:hover {
      background: #32394d;
      color: #fff;
    }
    @media (max-width: 600px) {
      font-size: 0.9rem;
      padding: 0.1rem 1.3rem;
    }
  }
`;

const CustomText = styled.div`
  margin: 1rem 0;
  font-size: 0.95rem;
  color: #888888;
`;

const StyledSmall = styled.small`
  color: red;
  margin-bottom: 1rem;
`;

export default AddTypeModal;
