import { useHistory } from 'react-router-dom';
// Styled Comps
import { StyledTable, ImageThumb } from '../StyledComps/styledComponents';
// Functions
import { findProductType } from '../functions/findProductType';
import { displayThumbUrl } from '../functions/displayThumbUrl';
const ProductTable = ({ products, isLoading, allTypes, modalHandler }) => {
  const history = useHistory();
  const openProductHandler = (id) => {
    history.push(`product_view/${id}`);
  };
  return (
    <>
      {!isLoading && (
        <StyledTable clickable={true}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Type Image</th>
              <th>Length</th>
              <th>Height</th>
              <th>Width</th>
              <th className="location">Place</th>
              <th>Reserved</th>
              <th>Defects</th>
              <th>Quantity</th>
              <th>
                m<sup>2</sup>
              </th>
            </tr>
          </thead>
          {products && (
            <tbody>
              {products.map((prod) => (
                <tr
                  key={prod._id}
                  id={prod._id}
                  onClick={() => openProductHandler(prod._id)}
                >
                  <td>{findProductType(allTypes, prod.product.product_type)}</td>
                  <td>
                    <span>
                      <ImageThumb
                        alt="Product Texture"
                        loading="lazy"
                        src={displayThumbUrl(prod.product.type_img)}
                        onClick={modalHandler}
                        data-image={prod.product.type_img}
                      />
                    </span>
                  </td>
                  <td>{prod.dimensions.long}</td>
                  <td>{prod.dimensions.short}</td>
                  <td>{prod.dimensions.width}</td>
                  <td>{prod.warehouse_location}</td>
                  <td className="mid">
                    {prod.reserved.isReserved ? prod.reserved.id : ''}
                  </td>
                  <td className="mid">{prod.defect ? 'YES' : 'NO'}</td>
                  <td className="mid">{prod.amount ?? 0}</td>
                  <td className="mid">
                    {(
                      ((prod.dimensions.long ?? 0) *
                        (prod.dimensions.short ?? 0) *
                        (prod.amount ?? 0)) /
                      10000
                    ).toFixed(2)}
                  </td>{' '}
                </tr>
              ))}
            </tbody>
          )}
        </StyledTable>
      )}
    </>
  );
};

export default ProductTable;
