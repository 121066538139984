import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { displayError } from '../functions/displayErrorString';
import axios from 'axios';
import { BtnGreen } from '../Components/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const headerStyle = {
  display: 'flex',
  marginBottom: '5px',
};

const rowStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
};

const labelStyle = {
  width: '100px',
  marginRight: '10px',
};

const inputStyle = {
  flex: '1',
  marginRight: '10px',
  maxWidth: '160px',
};

const buttonStyle = {
  width: '70px',
};

const headerItemStyle = {
  marginRight: '10px',
  textAlign: 'center',
};

const ProductDetails = ({
  editable,
  modalHandler,
  inputs,
  inputHandler,
  selectHandler,
  inputErrors,
  isEdit,
  currentProduct,
  refreshProducts,
  fetchProductEvents,
}) => {
  const types = Object.values(useSelector((state) => state.types.types));
  const errors = (errStr) => {
    if (inputErrors === undefined) return false;
    if (inputErrors.errors.includes(errStr)) return true;
  };

  const [isLoadSave, setIsLoadSave] = useState(false);

  const postProductEvent = async (data, type) => {
    try {
      const response = await fetch('/api/product_events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: type,
          date: data.date,
          amount: data.quantity,
          onePrice: data.onePrice,
          reason: data.reason,
          productId: currentProduct._id,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error saving product event:', error);
      alert(error.message); // Display the error message in an alert
    }
  };
  const [errorMessages, setErrorMessages] = useState({
    pajamavimas: '',
    pardavimas: '',
    nurasymas: '',
  });
  const validateRow = (rowData, rowName) => {
    if (!rowData.date || !rowData.quantity || !rowData.reason) {
      alert('All fields are required'); // Show an alert if any field is empty
      return false; // Validation failed
    }
    return true; // Validation passed
  };
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };
  const handleDateChange = (date, setData, data) => {
    const formattedDate = formatDate(date);
    setData({ ...data, date: formattedDate });
  };

  const [pajamavimasData, setPajamavimasData] = useState({
    date: '',
    quantity: '',
    onePrice: '',
    reason: '',
  });
  const [pardavimasData, setPardavimasData] = useState({
    date: '',
    quantity: '',
    onePrice: '',
    reason: '',
  });
  const [nurasymasData, setNurasymasData] = useState({
    date: '',
    quantity: '',
    onePrice: '',
    reason: '',
  });
  const handlePajamavimasChange = (e) => {
    setPajamavimasData({ ...pajamavimasData, [e.target.name]: e.target.value });
  };

  const handlePardavimasChange = (e) => {
    setPardavimasData({ ...pardavimasData, [e.target.name]: e.target.value });
  };

  const handleNurasymasChange = (e) => {
    setNurasymasData({ ...nurasymasData, [e.target.name]: e.target.value });
  };
  const savePajamavimas = async () => {
    if (validateRow(pajamavimasData, 'pajamavimas')) {
      setIsLoadSave(true); // Start loading
      const result = await postProductEvent(pajamavimasData, 0);
      if (result) {
        setPajamavimasData({ date: '', quantity: '', reason: '', onePrice: '' });

        // Refresh products
        refreshProducts();
        fetchProductEvents();
      }
      setIsLoadSave(false); // Stop loading
    }
  };

  const savePardavimas = async () => {
    if (validateRow(pardavimasData, 'pardavimas')) {
      setIsLoadSave(true); // Start loading
      const result = await postProductEvent(pardavimasData, 1);
      if (result) {
        setPardavimasData({ date: '', quantity: '', reason: '', onePrice: '' });
        refreshProducts();
        fetchProductEvents();
      }
      setIsLoadSave(false); // Stop loading
    }
  };

  const saveNurasymas = async () => {
    if (validateRow(nurasymasData, 'nurasymas')) {
      setIsLoadSave(true); // Start loading
      const result = await postProductEvent(nurasymasData, 2);
      if (result) {
        console.log('Nurašymas Data saved successfully', result);
        setNurasymasData({ date: '', quantity: '', reason: '', onePrice: '' });
        refreshProducts();
        fetchProductEvents();
      }
      setIsLoadSave(false); // Stop loading
    }
  };
  errors();
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchLocations = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get('/api/locations');
        setLocations(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching locations:', error);
        setIsLoading(false);
      }
    };

    fetchLocations();
  }, []);
  return (
    <>
      <StyledForm>
        <div className="form-row">
          <div className="form-control">
            <div className="input-top">
              <label htmlFor="type">Type</label>
              {editable && (
                <small className="error-msg">
                  {displayError(inputErrors.errors, 'type', '* Type is required')}
                </small>
              )}
            </div>
            <div className="select-control">
              <select
                id="type"
                name="type"
                defaultValue={inputs.product.product_type}
                onChange={selectHandler}
                disabled={editable ? false : true}
                readOnly={editable ? false : true}
              >
                <option value="" id="" imgsrc="">
                  --- Select Type ---
                </option>
                {types.map((type) => (
                  <option
                    key={type._id}
                    id={type.type_id}
                    value={type.type_id}
                    imgsrc={type.image}
                  >
                    {type.name}
                  </option>
                ))}
              </select>
              {inputs.product.type_img !== '' ? (
                <div className="texture" onClick={modalHandler}>
                  <img src={inputs.product.type_img} alt="texture" />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="form-control">
            <div className="input-top">
              <label htmlFor="type">Amount</label>
            </div>
            <div className="select-control" style={{ marginTop: 6, fontSize: 16 }}>
              {currentProduct.amount ?? 0}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-control third">
            <div className="input-top">
              <label htmlFor="length">Length</label>
              {editable && (
                <small className="error-msg">
                  {displayError(inputErrors.errors, 'length', '* Length is required')}
                </small>
              )}
            </div>
            <input
              type="number"
              id="length"
              min="0"
              className={errors('dimensions_error') ? 'red_border' : ''}
              value={inputs.length}
              onChange={inputHandler}
              disabled={editable ? false : true}
              readOnly={editable ? false : true}
            />
          </div>
          <div className="form-control third">
            <div className="input-top">
              <label htmlFor="height">Height</label>
              {editable && (
                <small className="error-msg">
                  {displayError(inputErrors.errors, 'height', '* height is required')}
                </small>
              )}
            </div>
            <input
              type="number"
              id="height"
              min="0"
              className={errors('dimensions_error') ? 'red_border' : ''}
              value={inputs.height}
              onChange={inputHandler}
              disabled={editable ? false : true}
              readOnly={editable ? false : true}
            />
          </div>
          <div className="form-control third">
            <div className="input-top">
              <label htmlFor="width">Width</label>
              {editable && (
                <small className="error-msg">
                  {displayError(inputErrors.errors, 'width', '* Width is required')}
                </small>
              )}
            </div>
            <input
              type="number"
              id="width"
              min="0"
              value={inputs.width}
              onChange={inputHandler}
              disabled={editable ? false : true}
              readOnly={editable ? false : true}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-control">
            <div className="input-top">
              <label htmlFor="warehouse_location">Warehouse location</label>
              {editable && (
                <small className="error-msg">
                  {displayError(inputErrors.errors, 'location', '* Location is required')}
                </small>
              )}
            </div>
            {isLoading ? (
              <p>Loading locations...</p>
            ) : (
              <select
                id="warehouse_location"
                name="warehouse_location"
                value={inputs.location}
                onChange={inputHandler}
                disabled={!editable}
              >
                <option value="x">x</option>
                {locations.map((location) => (
                  <option key={location._id} value={location.title}>
                    {location.title} - {location.value}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="form-control">
            <div className="input-top">
              <label htmlFor="last_edited">Edited by</label>
              {editable && (
                <small className="error-msg">
                  {displayError(
                    inputErrors.errors,
                    'editedBy',
                    '* Edited by is required',
                  )}
                </small>
              )}
            </div>
            <input
              type="text"
              id="editedBy"
              value={inputs.editedBy}
              onChange={inputHandler}
              disabled={true}
              readOnly={true}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-control full">
            <label htmlFor="comments">Comments</label>
            <textarea
              id="comments"
              defaultValue={inputs.comments}
              onChange={inputHandler}
              disabled={editable ? false : true}
              readOnly={editable ? false : true}
            ></textarea>
          </div>
        </div>
        <div className="form-row">
          <div className="form-control inline">
            <input
              type="checkbox"
              checked={inputs.reserved}
              onChange={inputHandler}
              id="reserved"
              disabled={editable ? false : true}
              readOnly={editable ? false : true}
            />
            <label htmlFor="comments">&nbsp; Reserved</label>
          </div>
          {inputs.reserved && (
            <div className="form-control inline second">
              <div className="input-top">
                <label htmlFor="reserved_id">Reservation ID</label>
              </div>
              <input
                type="text"
                id="reserveId"
                className={errors('reserveId') ? 'red_border' : ''}
                disabled={editable ? false : true}
                readOnly={editable ? false : true}
                value={inputs.reserveId}
                onChange={inputHandler}
              />
            </div>
          )}
        </div>
        <div style={{ marginBottom: 20, marginTop: 8 }} className="form-control inline">
          <input
            type="checkbox"
            checked={inputs.defect}
            onChange={inputHandler}
            id="defect"
            disabled={editable ? false : true}
            readOnly={editable ? false : true}
          />
          <label htmlFor="comments">&nbsp; Defect</label>
        </div>
      </StyledForm>
      {isEdit &&
        (isLoadSave ? (
          <LoadingOverlay>
            <Spinner />
          </LoadingOverlay>
        ) : (
          <div style={{ margin: '24px 0' }}>
            <div style={headerStyle}>
              <span style={{ ...headerItemStyle, width: labelStyle.width }}></span>
              <span style={{ ...headerItemStyle, width: inputStyle.maxWidth }}>
                <b>Data</b>
              </span>
              <span style={{ ...headerItemStyle, width: inputStyle.maxWidth }}>
                <b>Kiekis, vnt</b>
              </span>
              <span
                style={{ ...headerItemStyle, width: inputStyle.maxWidth, marginLeft: 12 }}
              >
                <b>1 vnt. kaina be PVM</b>
              </span>
              <span style={{ ...headerItemStyle, width: inputStyle.maxWidth }}>
                <b>Priežastis</b>
              </span>
              <span style={{ ...headerItemStyle, width: buttonStyle.width }}></span>
            </div>
            <div style={rowStyle}>
              <label style={labelStyle}>
                <b>Pajamavimas</b>
              </label>
              <span style={{ marginRight: 10 }}>
                <DatePicker
                  selected={pajamavimasData.date ? new Date(pajamavimasData.date) : null}
                  onChange={(date) =>
                    handleDateChange(date, setPajamavimasData, pajamavimasData)
                  }
                  dateFormat="yyyy/MM/dd"
                  placeholderText="yyyy/mm/dd"
                  style={inputStyle}
                />
              </span>
              <input
                style={inputStyle}
                type="number"
                name="quantity"
                min="0"
                value={pajamavimasData.quantity}
                onChange={handlePajamavimasChange}
              />
              <input
                style={inputStyle}
                type="number"
                min="0"
                name="onePrice"
                value={pajamavimasData.onePrice}
                onChange={handlePajamavimasChange}
              />
              <input
                style={inputStyle}
                type="text"
                name="reason"
                value={pajamavimasData.reason}
                onChange={handlePajamavimasChange}
              />
              <BtnGreen handler={savePajamavimas}>Save</BtnGreen>
            </div>

            <div style={rowStyle}>
              <label style={labelStyle}>
                <b>Pardavimas</b>
              </label>
              <span style={{ marginRight: 10 }}>
                <DatePicker
                  selected={pardavimasData.date ? new Date(pardavimasData.date) : null}
                  onChange={(date) =>
                    handleDateChange(date, setPardavimasData, pardavimasData)
                  }
                  dateFormat="yyyy/MM/dd"
                  placeholderText="yyyy/mm/dd"
                  style={inputStyle}
                />
              </span>
              <input
                style={inputStyle}
                type="number"
                min="0"
                name="quantity"
                value={pardavimasData.quantity}
                onChange={handlePardavimasChange}
              />
              <input
                style={inputStyle}
                type="number"
                min="0"
                name="onePrice"
                value={pardavimasData.onePrice}
                onChange={handlePardavimasChange}
              />
              <input
                style={inputStyle}
                type="text"
                name="reason"
                value={pardavimasData.reason}
                onChange={handlePardavimasChange}
              />
              <BtnGreen handler={savePardavimas}>Save</BtnGreen>
            </div>

            <div style={rowStyle}>
              <label style={labelStyle}>
                <b>Nurašymas</b>
              </label>
              <span style={{ marginRight: 10 }}>
                <DatePicker
                  selected={nurasymasData.date ? new Date(nurasymasData.date) : null}
                  onChange={(date) =>
                    handleDateChange(date, setNurasymasData, nurasymasData)
                  }
                  dateFormat="yyyy/MM/dd"
                  placeholderText="yyyy/mm/dd"
                  style={inputStyle}
                />
              </span>
              <input
                style={inputStyle}
                type="number"
                name="quantity"
                value={nurasymasData.quantity}
                onChange={handleNurasymasChange}
                min="0"
              />
              <input
                style={inputStyle}
                type="number"
                min="0"
                name="onePrice"
                value={nurasymasData.onePrice}
                onChange={handleNurasymasChange}
              />
              <input
                style={inputStyle}
                type="text"
                name="reason"
                value={nurasymasData.reason}
                onChange={handleNurasymasChange}
              />
              <BtnGreen handler={saveNurasymas}>Save</BtnGreen>
            </div>
          </div>
        ))}
    </>
  );
};

const StyledForm = styled.form`
  width: 100%;
  max-width: 1200px;
  .form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1rem 0rem;
    min-width: 600px;
    @media (max-width: 650px) {
      flex-direction: column;
      min-width: 100%;
      padding: 0;
    }
  }
  .form-row:last-of-type {
    padding-bottom: 2rem;
    @media (max-width: 650px) {
      padding-top: 0.5rem;
    }
  }
  .form-control {
    display: flex;
    flex-direction: column;
    width: 48%;
    min-width: 200px;
    .select-control {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
    }
    .texture {
      cursor: pointer;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0rem 0.5rem;
      overflow: hidden;
      border: solid 1px #a3a3a3;
      img {
        width: 100%;
        height: 100%;
      }
    }
    @media (max-width: 650px) {
      width: 100%;
      padding: 0.3rem 0rem;
    }
    &.form-control.third {
      width: 28%;
      min-width: 100px;
      @media (max-width: 650px) {
        width: 100%;
        min-width: 200px;
      }
    }
    &.form-control.full {
      width: 100%;
      @media (max-width: 650px) {
        width: 100%;
      }
    }
    @media (max-width: 650px) {
      width: 100%;
    }
    label {
      padding: 0 0 0.3rem 0.2rem;
      font-size: 1.2rem;
    }
    .error-msg {
      color: red;
      padding-left: 1rem;
    }
    input,
    textarea,
    select {
      width: 100%;
      padding: 0.3rem 1rem;
      font-family: 'Montserrat', sans-serif;
      font-size: 1rem;
      border-radius: 10px;
      letter-spacing: 1px;
      border: solid 1px #a3a3a3;
      outline-width: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    textarea#comments {
      resize: none;
      overflow: scroll;
    }
    select {
      width: 90%;
      padding: 0.28rem 1rem;
    }
    input#reserved {
      display: inline;
      width: auto;
    }
    input.red_border {
      border: solid 1px red;
    }

    input:focus,
    textarea:focus {
      border: solid 1px black;
    }
  }
  .form-control.inline {
    flex-direction: row;
    align-items: center;
    width: auto;
    @media (max-width: 650px) {
      padding: 0 0 0.3rem 0.2rem;
    }
    label {
      padding: 0 1rem;
      @media (max-width: 650px) {
        padding: 0 0rem;
      }
    }
    input {
      width: auto;
    }
  }

  .form-control.inline.second {
    input {
      @media (max-width: 650px) {
        margin-top: 0.1rem;
        width: 100%;
      }
    }
    @media (max-width: 650px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Spinner = styled.div`
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default ProductDetails;
