import React, { useState } from 'react';
// Styled Comps
import { Wrapper } from '../StyledComps/styledComponents';
import styled from 'styled-components';
// Components
import AllTypesTable from '../Components/AllTypesTable';
import { Btn } from '../Components/Button';
import AddTypeModal from '../Components/AddTypeModal';
import MessageModal from '../Components/MessageModal';
import ImageModal from '../Components/ImageModal';
import ConfirmMessageModal from '../Components/ConfirmMessageModal';
import axios from 'axios';
// Hooks
import { useShowMsgModal } from '../hooks/useShowMsgModal';
import { useConfirmMsgModal } from '../hooks/useConfirmMsgModal';
import { useModalHandler } from '../hooks/useModalHandler';
// Functions
import { findPublicId } from '../functions/findPublicId';
// Icons
import { AiOutlinePlus } from 'react-icons/ai';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewTypeAction,
  removeTypeAction,
  typesAction,
} from '../Redux/actions/typesAction';
const TypesPage = () => {
  const { types } = useSelector((state) => state.types);
  const { products } = useSelector((state) => state.products);
  const typesArray = Object.values(types);
  const [displayAddTypeModal, setDisplayAddTypeModal] = useState(false);
  const { imgOpen, modalHandler } = useModalHandler();
  const [typeInputs, setTypeInputs] = useState({
    name: '',
    type_id: '',
    image: '',
  });
  const [typeInputError, setTypeInputError] = useState(null);
  const { showMsg, showModalMsgHandler } = useShowMsgModal();
  const { showConfirmModal, confirmModalhandler } = useConfirmMsgModal();
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [typeToDeleteID, setTypeToDeleteId] = useState({
    name_id: '',
    id: '',
  });

  const dispatch = useDispatch();
  const { username } = useSelector((state) => state.user);

  const typeInputHandler = (e) => {
    setTypeInputs({
      ...typeInputs,
      [e.target.id]: e.target.value,
    });
  };

  const calculateNextTypeId = () => {
    const highestId = typesArray.reduce(
      (max, type) => Math.max(max, parseInt(type.type_id) || 0),
      0,
    );
    return (highestId + 1).toString().padStart(3, '0');
  };

  const typeImgInputHandler = (imgStr) => {
    setTypeInputs({
      ...typeInputs,
      image: imgStr,
    });
  };

  const clearAllInputs = () => {
    setTypeInputs({ name: '', type_id: '', image: '' });
  };

  const openModalHandler = () => {
    setDisplayAddTypeModal(!displayAddTypeModal);
  };

  const closeModalhandler = (e) => {
    if (e.target.classList.contains('outer')) {
      setTypeInputError(null);
      clearAllInputs();
      openModalHandler();
    }
  };

  const isEditing =
    typeInputs.type_id && typesArray.some((type) => type.type_id === typeInputs.type_id);

  const submitType = async () => {
    setTypeInputError(null);

    if (typeInputs.name === '' || typeInputs.type_id === '' || typeInputs.image === '') {
      return setTypeInputError('* All fields are required');
    }

    if (!isEditing) {
      const matchName = typesArray.some(
        (el) => el.name.trim().toLowerCase() === typeInputs.name.trim().toLowerCase(),
      );
      if (matchName) {
        return setTypeInputError('* Name already exist');
      }
    }

    if (isEditing) {
      try {
        const response = await axios.put(`/api/types/update_type/${typeInputs.type_id}`, {
          name: typeInputs.name,
          image: typeInputs.image,
          username: username,
        });
        console.log(response.data);
        showModalMsgHandler('Type updated successfully');
        dispatch(typesAction());
      } catch (error) {
        console.error('Error updating type:', error);
        setTypeInputError('Unable to update type');
      }
    } else {
      dispatch(addNewTypeAction(typeInputs, showModalMsgHandler));
    }

    openModalHandler();
  };

  const productDeleteHandler = (name_id, id) => {
    setTypeToDeleteId({ name_id, id });
    confirmModalhandler();
  };

  const confirmDeleteHandler = async () => {
    const typeImg = typesArray.find((el) => el._id === typeToDeleteID.id).image;
    const public_id = findPublicId(typeImg);
    confirmModalhandler();
    dispatch(
      removeTypeAction(typeToDeleteID.id, username, showModalMsgHandler, public_id),
    );
    setTypeToDeleteId({ name_id: '', id: '' });
  };

  const openAddModalHandler = () => {
    setTypeInputs({ name: '', type_id: calculateNextTypeId(), image: '' });
    setIsEditingMode(false);
    setDisplayAddTypeModal(true);
  };

  const productEditHandler = (type) => {
    setTypeInputs({
      name: type.name,
      type_id: type.type_id,
      image: type.image,
    });
    setIsEditingMode(true);
    setDisplayAddTypeModal(true);
  };

  // Calculate quantity and m² for each type
  const calculateQuantitiesAndAreas = () => {
    return typesArray.map((type) => {
      const productsOfType = products.filter(
        (product) => product.product.product_type === type.type_id,
      );

      const quantity = productsOfType.reduce(
        (sum, product) => sum + (product.amount ?? 0),
        0,
      );

      const totalArea = productsOfType.reduce((sum, product) => {
        const area =
          ((product.dimensions.long ?? 0) *
            (product.dimensions.short ?? 0) *
            (product.amount ?? 0)) /
          10000;
        return sum + area;
      }, 0);

      return {
        ...type,
        quantity, // Sum of all product amounts
        totalArea: totalArea.toFixed(2), // Total area in square meters
      };
    });
  };

  const typesWithQuantitiesAndAreas = calculateQuantitiesAndAreas();

  return (
    <>
      {imgOpen.open && <ImageModal img={imgOpen} modalHandler={modalHandler} />}
      {showConfirmModal && (
        <ConfirmMessageModal
          msg={`Delete Type ${typeToDeleteID.name_id} ?`}
          cancelHandler={confirmModalhandler}
          confirmHandler={confirmDeleteHandler}
        />
      )}
      {showMsg.display && (
        <MessageModal msg={showMsg.msg} link={showMsg.link} linkTxt={showMsg.linkTxt} />
      )}
      {displayAddTypeModal && (
        <AddTypeModal
          typeInputs={typeInputs}
          isEditingMode={isEditingMode}
          typeInputHandler={typeInputHandler}
          typeImgInputHandler={typeImgInputHandler}
          submitType={submitType}
          closeModalhandler={closeModalhandler}
          typeInputError={typeInputError}
        />
      )}
      <Wrapper>
        <Header>
          All types
          <Btn handler={openAddModalHandler}>
            <AiOutlinePlus /> &nbsp; Add new type
          </Btn>
        </Header>
        <div className="line"></div>
        <div className="container_overflowx_scroll">
          <AllTypesTable
            username={username}
            productEditHandler={productEditHandler}
            productDeleteHandler={productDeleteHandler}
            typesArray={typesWithQuantitiesAndAreas} // Pass the calculated data here
            modalHandler={modalHandler}
          />
        </div>
      </Wrapper>
    </>
  );
};

const Header = styled.h1`
  display: flex; /* Enable flexbox */
  justify-content: space-between; /* Align content with space between */
  align-items: center; /* Center align items vertically */
  width: 100%; /* Take full width */
  /* Add any additional styling here */
`;

export default TypesPage;
