import React, { useRef, useState, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
// Components
import ImageModal from '../Components/ImageModal';
import ImageCarousel from '../Components/ImageCarousel';
import ProductDetails from '../Components/ProductDetails';
import { BtnLink, BtnRedSm, BtnSm } from '../Components/Button';
// Styled comps
import { Wrapper, ButtonsWrapper, StyledTable } from '../StyledComps/styledComponents';
// Hooks
import { useFindByUrl } from '../hooks/useFindByUrl';
import { useModalHandler } from '../hooks/useModalHandler';
import { useProductInputs } from '../hooks/useProductInputs';
// Icons
import { BsArrowLeft } from 'react-icons/bs';
// Redux
import { productsAction } from '../Redux/actions/productsAction';
import EditEventModal from '../Components/EditEventModal';
import QRCode from 'qrcode.react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class ComponentToPrint extends React.Component {
  render() {
    const { currentProduct } = this.props;
    const qrCodeUrl = `https://donstone.onrender.com/product_view/${currentProduct._id}`;

    return (
      <div>
        <p> Barcode: {currentProduct._id}</p>
        <div style={{ paddingTop: '20px' }}>
          <QRCode value={qrCodeUrl} size={256} />
          <p> Scan for details</p>
        </div>
      </div>
    );
  }
}
const ProductView = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const componentRef = useRef();
  const [editable] = useState(false);
  const [productEvents, setProductEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [eventToEdit, setEventToEdit] = useState({ amount: '', reason: '' });
  const [typeFilters, setTypeFilters] = useState({
    pajamavimas: true,
    pardavimas: true,
    nurasymas: true,
  });
  const ref = useRef();

  const { imgOpen, modalHandler } = useModalHandler();
  const currentProduct = useFindByUrl();
  const { inputs, inputHandler, selectHandler } = useProductInputs(
    currentProduct,
    currentProduct.edited_by ?? '',
  );
  const { userType } = useSelector((state) => state.user);
  const { name, token, username } = useSelector((state) => state.user);
  const handleFilter = () => {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const result = productEvents
      .filter((event) => {
        if (start && end) {
          const eventDate = new Date(event.date);
          return eventDate >= start && eventDate <= end;
        }
        return true;
      })
      .filter((event) => {
        if (typeFilters.pajamavimas && event.type === 0) return true;
        if (typeFilters.pardavimas && event.type === 1) return true;
        if (typeFilters.nurasymas && event.type === 2) return true;
        return false;
      });

    setFilteredEvents(result);
  };
  const clearFilter = () => {
    setFilteredEvents(productEvents);
    setStartDate('');
    setEndDate('');
    setTypeFilters({
      pajamavimas: true,
      pardavimas: true,
      nurasymas: true,
    });
  };
  const handleTypeFilterChange = (type) => {
    setTypeFilters((prevFilters) => ({
      ...prevFilters,
      [type]: !prevFilters[type],
    }));
  };

  const fetchProductEvents = async () => {
    try {
      const response = await axios.get(`/api/product_events/${currentProduct._id}`);
      const productEvents = response.data.data;
      setProductEvents(productEvents);
      setFilteredEvents(productEvents);
    } catch (error) {
      console.error('Error fetching product events:', error);
    }
  };
  useEffect(() => {
    if (currentProduct._id) {
      fetchProductEvents();
    }
  }, []);

  const handleEdit = (event) => {
    setEventToEdit(event);
    setShowEditModal(true);
  };

  const handleInputChange = (e, field) => {
    setEventToEdit({ ...eventToEdit, [field]: e.target.value });
  };
  const dispatch = useDispatch();

  const refreshProducts = () => {
    dispatch(productsAction(token));
  };
  const handleEditSubmit = async () => {
    if (window.confirm('Are you sure you want to update this event?')) {
      try {
        // Call the backend API to update the event
        const response = await axios.patch(`/api/product_events/${eventToEdit._id}`, {
          ...eventToEdit,
        });

        // Check if the update was successful
        if (response.status === 200) {
          // Replace the old event data with the new one in the filteredEvents state
          setFilteredEvents(
            filteredEvents.map((event) =>
              event._id === eventToEdit._id ? { ...eventToEdit } : event,
            ),
          );

          // Optionally, refresh the products if they are affected by this event
          refreshProducts();
        } else {
          console.error('Failed to update the product event:', response.data.message);
        }
      } catch (error) {
        console.error('Error updating the product event:', error);
        // Optionally, you might want to show an error message to the user here
      }

      setShowEditModal(false); // Close the modal after submission
    }
  };
  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        await axios.delete(`/api/product_events/${id}`);
        setFilteredEvents(filteredEvents.filter((event) => event._id !== id));
        fetchProductEvents();
        refreshProducts();
      } catch (error) {
        console.error('Error deleting the product event:', error);
      }
    }
  };
  const isDeleteVisible = username === 'Donatas' || username === 'admin';

  return (
    <>
      {imgOpen.open && <ImageModal modalHandler={modalHandler} img={imgOpen} />}
      {currentProduct && (
        <>
          <Wrapper imgOpen={imgOpen.open}>
            <h1>Product</h1>
            <div className="line" />
            <ProductDetails
              isEdit={true}
              refreshProducts={refreshProducts}
              inputs={inputs}
              inputHandler={inputHandler}
              currentProduct={currentProduct}
              selectHandler={selectHandler}
              editable={editable}
              fetchProductEvents={fetchProductEvents}
              modalHandler={modalHandler}
            />
            <ImageCarousel
              modalHandler={modalHandler}
              images={currentProduct.product_img}
            />

            <ButtonsWrapper>
              <BtnLink link="/home">
                <BsArrowLeft size={20} />
                <span style={{ paddingLeft: '0.2rem' }}>Back</span>
              </BtnLink>
              <ReactToPrint
                trigger={() => <button>Print PDF</button>}
                content={() => componentRef.current}
              />
              {userType === 'admin' && (
                <div className="btns-right">
                  <BtnLink link={`/product_edit/${currentProduct._id}`}>Edit</BtnLink>

                  <div style={{ display: 'none' }}>
                    <ComponentToPrint
                      ref={componentRef}
                      currentProduct={currentProduct}
                    />
                  </div>
                </div>
              )}
            </ButtonsWrapper>
            <div style={{ marginBottom: '20px', marginTop: 30 }}>
              <label htmlFor="startDate">Start Date:</label>
              <DatePicker
                enableTabLoop={false}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="yyyy/MM/dd"
                placeholderText="yyyy/mm/dd"
              />

              <label htmlFor="endDate" style={{ marginLeft: '10px' }}>
                End Date:
              </label>
              <DatePicker
                enableTabLoop={false}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="yyyy/MM/dd"
                placeholderText="yyyy/mm/dd"
              />

              <span style={{ marginLeft: 20 }}>
                <label>
                  <input
                    type="checkbox"
                    checked={typeFilters.pajamavimas}
                    onChange={() => handleTypeFilterChange('pajamavimas')}
                  />
                  Pajamavimas
                </label>
                <label style={{ marginLeft: '10px' }}>
                  <input
                    type="checkbox"
                    checked={typeFilters.pardavimas}
                    onChange={() => handleTypeFilterChange('pardavimas')}
                  />
                  Pardavimas
                </label>
                <label style={{ marginLeft: '10px' }}>
                  <input
                    type="checkbox"
                    checked={typeFilters.nurasymas}
                    onChange={() => handleTypeFilterChange('nurasymas')}
                  />
                  Nurašymas
                </label>
              </span>
              <button
                style={{ marginLeft: 12 }}
                className="mini ui button"
                onClick={handleFilter}
              >
                Filter
              </button>
              <button className="mini ui button" onClick={clearFilter}>
                Clear Filter
              </button>
            </div>
            <StyledTable>
              <thead>
                <tr>
                  <th>Date</th>
                  <th style={{ textAlign: 'center' }}>Pajamavimas</th>
                  <th style={{ textAlign: 'center' }}>Pardavimas</th>
                  <th style={{ textAlign: 'center' }}>Nurašymas</th>
                  <th style={{ textAlign: 'center' }}>Likutis</th>
                  <th>1 vnt. kaina be PVM</th>

                  <th>Priežastis</th>
                  {isDeleteVisible ? <th>Actions</th> : ''}
                </tr>
              </thead>
              <tbody>
                {filteredEvents.map((event, index) => (
                  <tr key={index}>
                    <td>{event.date.replace(/-/g, '.')}</td>
                    <td style={{ textAlign: 'center' }}>
                      {event.type === 0 ? event.amount : ''}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {event.type === 1 ? event.amount : ''}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {event.type === 2 ? event.amount : ''}
                    </td>
                    <td
                      style={{
                        color: event.type === 0 ? 'green' : 'red',
                        textAlign: 'center',
                      }}
                    >
                      {event.restOf || 0}
                    </td>
                    <td style={{ textAlign: 'center', paddingRight: 20 }}>
                      {event.onePrice ? `${event.onePrice}€` : ''}
                    </td>
                    <td>{event.reason}</td>
                    {isDeleteVisible ? (
                      <td>
                        <button
                          className="ui button mini"
                          onClick={() => handleEdit(event)}
                        >
                          Edit
                        </button>
                        <button
                          className="ui button xs negative mini"
                          onClick={() => handleDelete(event._id)}
                        >
                          Delete
                        </button>
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                ))}
              </tbody>
            </StyledTable>
            {showEditModal && (
              <EditEventModal
                eventToEdit={eventToEdit}
                onInputChange={handleInputChange}
                onSubmit={handleEditSubmit}
                closeModalHandler={() => setShowEditModal(false)}
                isEditingMode={true}
              />
            )}
          </Wrapper>
        </>
      )}
    </>
  );
};

export default ProductView;
