import styled from 'styled-components';
const FilterRow = ({ filterValuesHandler, filterSelectHandler, types, filterInputs }) => {
  return (
    <FilterRowCont>
      <select
        name="types-select"
        id="type"
        value={filterInputs.type || ''}
        onChange={filterSelectHandler}
      >
        <option value="" id="DEFAULT">
          Type
        </option>
        {types.map((type) => (
          <option key={type._id} id={type.type_id} value={type.type_id}>
            {type.name} - {type.type_id}
          </option>
        ))}
      </select>
      <input
        id="length"
        type="number"
        min="0"
        placeholder="Length..."
        value={filterInputs.length || ''}
        onChange={filterValuesHandler}
      />
      <input
        id="height"
        type="number"
        placeholder="Height..."
        value={filterInputs.height || ''}
        onChange={filterValuesHandler}
      />
      <input
        id="width"
        type="number"
        min="0"
        placeholder="Width..."
        value={filterInputs.width || ''}
        onChange={filterValuesHandler}
      />
      <input
        id="place"
        type="text"
        placeholder="Place..."
        value={filterInputs.place || ''}
        onChange={filterValuesHandler}
      />
      <select
        name="reserved-select"
        id="reserved"
        value={filterInputs.reserved || ''}
        onChange={filterSelectHandler}
      >
        <option value="" id="DEFAULT">
          All
        </option>
        <option value={true} id="select-reserved">
          Reserved
        </option>
        <option value={false} id="select-not-reserved">
          Not Reserved
        </option>
      </select>
      <select
        name="defect-select"
        id="defect"
        value={filterInputs.defect || ''}
        onChange={filterSelectHandler}
      >
        <option value="" id="DEFAULT">
          All
        </option>
        <option value={true} id="select-defected">
          Defected
        </option>
        <option value={false} id="select-not-defected">
          Not Defected
        </option>
      </select>
      <input
        id="amount"
        type="number"
        placeholder="Min Quantity..."
        min="0"
        value={filterInputs.amount || ''}
        onChange={filterValuesHandler}
      />
      <input
        id="square"
        type="number"
        min="0"
        placeholder="Min m2..."
        value={filterInputs.square || ''}
        onChange={filterValuesHandler}
      />
    </FilterRowCont>
  );
};
const FilterRowCont = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0;

  select[name='types-select'],
  input#length,
  input#height,
  input#width,
  input#place,
  input#amount,
  input#square,
  select[name='reserved-select'] {
    flex: 1; /* This will ensure that all filter inputs take up equal space */
    min-width: 110px;
    padding: 0.1rem 0.5rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    border-radius: 5px;
    border: solid 1px #a3a3a3;
    outline-width: 0;
    &:focus {
      border: solid 1px black;
    }
  }

  select[name='types-select'] {
    flex: 2; /* This makes the 'Type' filter input wider, to span two columns */
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .reserved-check {
    display: flex;
    align-items: center;
    label {
      color: grey;
    }
  }
`;

export default FilterRow;
