import axios from 'axios';

export const autoLogin = async (dispatch, loginByIdAction) => {
  const jwt = localStorage.getItem('jwt');
  if (jwt) {
    try {
      const decoded = await axios.get('/api/decode/decode_token', {
        headers: {
          'auth-token': jwt,
        },
      });
      const userId = decoded.data.tokenId._id;
      await dispatch(loginByIdAction(userId, jwt));
    } catch (err) {
      console.log(err);
      localStorage.clear();
      throw err; // Throw an error to be caught in the calling function
    }
  }
};
