export const filterProducts = (products, filterInputs, sortInput, fn) => {
  let newArr = [...products];

  if (filterInputs.type !== '') {
    newArr = newArr.filter((item) => item.product.product_type === filterInputs.type);
  }
  if (filterInputs.length !== '') {
    newArr = newArr.filter((item) => item.dimensions.long >= filterInputs.length);
  }
  if (filterInputs.height !== '') {
    newArr = newArr.filter((item) => item.dimensions.short >= filterInputs.height);
  }
  if (filterInputs.width !== '') {
    newArr = newArr.filter((item) => item.dimensions.width >= filterInputs.width);
  }
  if (filterInputs.square !== '') {
    newArr = newArr.filter(
      (item) =>
        ((item.dimensions.long ?? 0) *
          (item.dimensions.short ?? 0) *
          (item.amount ?? 0)) /
          10000 >=
        filterInputs.square,
    );
  }
  if (filterInputs.amount !== '') {
    newArr = newArr.filter((item) => item.amount >= filterInputs.amount);
  }
  if (filterInputs.place !== '') {
    newArr = newArr.filter((item) =>
      item.warehouse_location.includes(filterInputs.place),
    );
  }
  if (filterInputs.reserved !== '') {
    newArr = newArr.filter(
      (item) => item.reserved.isReserved === strToBool(filterInputs.reserved),
    );
  }
  if (filterInputs.defect !== '') {
    newArr = newArr.filter((item) => item.defect === strToBool(filterInputs.defect));
  }

  const copyArr = [...newArr];

  if (sortInput === '') {
    newArr = copyArr;
  }
  if (sortInput === 'length_asc') {
    newArr = newArr.sort((a, b) => a.dimensions.long - b.dimensions.long);
  }
  if (sortInput === 'length_desc') {
    newArr = newArr.sort((a, b) => b.dimensions.long - a.dimensions.long);
  }
  if (sortInput === 'height_asc') {
    newArr = newArr.sort((a, b) => a.dimensions.short - b.dimensions.short);
  }
  if (sortInput === 'height_desc') {
    newArr = newArr.sort((a, b) => b.dimensions.short - a.dimensions.short);
  }
  if (sortInput === 'square_desc') {
    newArr = newArr.sort(
      (a, b) =>
        ((b.dimensions.long ?? 0) * (b.dimensions.short ?? 0) * (b.amount ?? 0)) / 10000 -
        ((a.dimensions.long ?? 0) * (a.dimensions.short ?? 0) * (a.amount ?? 0)) / 10000,
    );
  }
  if (sortInput === 'square_asc') {
    newArr = newArr.sort(
      (a, b) =>
        ((a.dimensions.long ?? 0) * (a.dimensions.short ?? 0) * (a.amount ?? 0)) / 10000 -
        ((b.dimensions.long ?? 0) * (b.dimensions.short ?? 0) * (b.amount ?? 0)) / 10000,
    );
  }
  if (sortInput === 'width_asc') {
    newArr = newArr.sort((a, b) => a.dimensions.width - b.dimensions.width);
  }
  if (sortInput === 'width_desc') {
    newArr = newArr.sort((a, b) => b.dimensions.width - a.dimensions.width);
  }
  if (sortInput === 'amount_asc') {
    newArr = newArr.sort((a, b) => a.amount - b.amount);
  }
  if (sortInput === 'amount_desc') {
    newArr = newArr.sort((a, b) => b.amount - a.amount);
  }
  fn([...newArr]);
};

const strToBool = (str) => {
  if (str === 'true') return true;
  if (str === 'false') return false;
  return;
};
