/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

// Styled Comps
import { Wrapper, StyledTable } from '../StyledComps/styledComponents';
// Components
import PageLoading from '../Components/PageLoading';
import Pagination from '../Components/Pagination';

import { fetchEvents, deleteEvents } from '../services/apiServices';
import formatDate from '../functions/formatDate';

// Functions
import { splitArray } from '../functions/spplitArray';
import { findProductType } from '../functions/findProductType';
// Styled
import styled from 'styled-components';
//Icons
import { FaSlidersH } from 'react-icons/fa';
const PER_PAGE = 15;

const options = [
  { value: 'loginEvents', label: 'Login Events' },
  { value: 'typeEvents', label: 'Type Events' },
  { value: 'productEvents', label: 'Product Events' },
];

class History extends React.Component {
  state = {
    showFilter: true,
    isLoading: false,
    isTableDataLoading: false,
    displayProducts: [],
    types: [],
    page: 1,
    products: [],
    selected: 'productEvents',
    allEvents: [],
    eventsOnDisplay: [],
    selectOption: options[2],
    unmodifiedList: [],
    filterInputs: {
      product_type: '',
      date: '',
      action: '',
      name: '',
      username: '',
    },
  };
  componentDidMount() {
    this.fetchAndLoad();
    const typesArray = Object.values(this.props.types.types || {});
    this.setState({
      types: typesArray,
    });
  }
  clearFilters = () => {
    this.setState(
      {
        filterInputs: {
          product_type: '',
          date: '',
          action: '',
          name: '',
          username: '',
        },
      },
      this.applyFilters, // Reapply filters to reset the displayed data
    );
  };
  fetchAndLoad = async () => {
    const events = await fetchEvents();
    this.setState(
      {
        allEvents: events,
      },
      () => {
        this.getSelectedType(this.state.selected);
      },
    );
  };

  changeFilterVisibility = () => {
    this.setState({
      showFilter: !this.state.showFilter,
    });
  };

  getSelectedType = async () => {
    const result = await this.state.allEvents.filter((obj) => {
      return obj.name === this.state.selected;
    });
    this.setState({
      eventsOnDisplay: result[0].events,
      unmodifiedList: result[0].events,
      isTableDataLoading: false,
    });
  };

  handleDeleteEvents = async (eventType) => {
    const res = await deleteEvents(eventType);
    if (res === 'success') {
      await this.fetchAndLoad();
    }
  };

  countHandler = (e) => {
    const idx = e.target.selectedIndex;
    const el = e.target.childNodes[idx];
    this.setState({
      page: +el.value,
    });
  };

  handleSelect = async (it) => {
    this.setState(
      {
        selected: it.value,
        selectOption: it,
        isTableDataLoading: true,
        filterInputs: {},
      },
      () => {
        this.fetchAndLoad();
        this.getSelectedType();
      },
    );
  };

  handleDeleteButton = async (type) => {
    await this.handleDeleteEvents(type);
  };

  handleFilterChange = (e) => {
    const { id, value } = e.target;
    this.setState(
      (prevState) => ({
        filterInputs: {
          ...prevState.filterInputs,
          [id]: value,
        },
      }),
      this.applyFilters,
    );
  };

  applyFilters = () => {
    const { unmodifiedList, filterInputs } = this.state;
    let filtered = unmodifiedList;

    if (filterInputs.product_type) {
      filtered = filtered.filter((event) =>
        event.product?.product_type.includes(filterInputs.product_type),
      );
    }

    if (filterInputs.date) {
      filtered = filtered.filter((event) =>
        new Date(event.date).toISOString().startsWith(filterInputs.date),
      );
    }

    if (filterInputs.action) {
      filtered = filtered.filter((event) => event.action.includes(filterInputs.action));
    }

    if (filterInputs.name) {
      filtered = filtered.filter((event) => event.name.includes(filterInputs.name));
    }

    if (filterInputs.username) {
      filtered = filtered.filter((event) =>
        event.username.includes(filterInputs.username),
      );
    }

    this.setState({ eventsOnDisplay: filtered });
  };

  renderSelectedTable = () => {
    const spltArray =
      this.state.eventsOnDisplay.length > 0
        ? splitArray(this.state.eventsOnDisplay, PER_PAGE)
        : [];
    switch (this.state.selected) {
      case 'productEvents':
        return this.state.isTableDataLoading ? (
          ''
        ) : (
          <StyledTable>
            <thead>
              <tr>
                <th>Type</th>
                <th>Action</th>
                <th>Date</th>
                <th>Edited By</th>
                <th>Length</th>
                <th>Height</th>
                <th>Width</th>
                <th>Place</th>
                <th>Reserved</th>
                <th>Comment</th>
                <th>Reason</th>
                <th>Removed by</th>
              </tr>
            </thead>
            {spltArray.length > 0 && (
              <tbody>
                {spltArray[this.state.page - 1].map((it) => (
                  <tr key={it._id}>
                    <td>{findProductType(this.state.types, it.product.product_type)}</td>
                    <td>{it.action}</td>
                    <td>{formatDate(it.date)}</td>
                    <th>{it.edited_by}</th>
                    <td>{it.dimensions.long}</td>
                    <td>{it.dimensions.short}</td>
                    <td>{it.dimensions.width}</td>
                    <td>{it.warehouse_location}</td>
                    <td className="mid">
                      {it.reserved.isReserved ? it.reserved.id : ''}
                    </td>
                    <td>{it.comment}</td>
                    <td>{it.delete_reason ? it.delete_reason : '-'}</td>
                    <td>{it.delete_user ? it.delete_user : '-'}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </StyledTable>
        );
      case 'typeEvents':
        return this.state.isTableDataLoading ? (
          ''
        ) : (
          <StyledTable>
            <thead>
              <tr>
                <th>Modified by</th>
                <th>Action</th>
                <th>Date</th>
                <th>Image</th>
                <th>Name</th>
                <th>Type id</th>
              </tr>
            </thead>
            {spltArray.length > 0 && (
              <tbody>
                {spltArray[this.state.page - 1].map((it) => (
                  <tr key={it._id}>
                    <td>{it.username}</td>
                    <td>{it.action}</td>
                    <th>{formatDate(it.date)}</th>
                    <td>
                      <img height="25" width="25" alt="" src={it.image} />
                    </td>
                    <td>{it.name}</td>
                    <td>{it.type_id}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </StyledTable>
        );
      case 'loginEvents':
        return this.state.isTableDataLoading ? (
          ''
        ) : (
          <StyledTable>
            <thead>
              <tr>
                <th>Username</th>
                <th>Date</th>
              </tr>
            </thead>
            {spltArray.length > 0 && (
              <tbody>
                {spltArray[this.state.page - 1].map((it) => (
                  <tr key={it._id}>
                    <td>{it.username}</td>
                    <td>{formatDate(it.time)}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </StyledTable>
        );
      default:
        return '';
    }
  };

  render() {
    const spltArray =
      this.state.eventsOnDisplay.length > 0
        ? splitArray(this.state.eventsOnDisplay, PER_PAGE)
        : [];

    return (
      <>
        {this.state.isLoading && <PageLoading />}
        <Wrapper>
          <h1>History</h1>
          <div style={{ paddingBottom: '8px' }}>
            <Select
              value={this.state.selectOption}
              onChange={this.handleSelect}
              defaultValue={options[0]}
              options={options}
            />
            <div
              onClick={() => this.handleDeleteButton(this.state.selected)}
              style={{ float: 'right' }}
              className="ui red button"
            >
              {`delete ${this.state.selected.slice(0, -6)} events`}
            </div>
          </div>
          <div>
            {this.state.showFilter && (
              <FilterRow
                filterValuesHandler={this.handleFilterChange}
                filterInputs={this.state.filterInputs}
                selectedEventType={this.state.selected}
                types={this.state.types} // Pass the types array here
                clearFilters={this.clearFilters} // Pass the clearFilters function
              />
            )}
          </div>
          <div className="container_overflowx_scroll">
            {this.state.eventsOnDisplay.length > 0 ? this.renderSelectedTable() : ''}
          </div>
          <Pagination spltArray={spltArray} countHandler={this.countHandler} />
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    types: state.types,
    user: state.user,
  };
};

export default connect(mapStateToProps)(History);

const FilterRow = ({
  filterValuesHandler,
  filterInputs,
  selectedEventType,
  types,
  clearFilters,
}) => {
  return (
    <FilterRowCont>
      {selectedEventType === 'productEvents' && (
        <>
          <select
            style={{ width: 80 }}
            id="product_type"
            value={filterInputs.product_type || ''}
            onChange={filterValuesHandler}
          >
            <option value="">Type</option>
            {types.map((type) => (
              <option key={type.type_id} value={type.type_id}>
                {type.name} - {type.type_id}
              </option>
            ))}
          </select>
          <input
            id="username"
            type="text"
            placeholder="Edited By..."
            value={filterInputs.username || ''}
            onChange={filterValuesHandler}
          />
          <select
            id="action"
            value={filterInputs.action || ''}
            onChange={filterValuesHandler}
          >
            <option value="">Select Action</option>
            <option value="created">Created</option>
            <option value="updated">Updated</option>
            <option value="deleted">Deleted</option>
          </select>
          <input
            id="date"
            type="date"
            value={filterInputs.date || ''}
            onChange={filterValuesHandler}
          />
        </>
      )}

      {selectedEventType === 'typeEvents' && (
        <>
          <input
            id="username"
            type="text"
            placeholder="Modified By..."
            value={filterInputs.username || ''}
            onChange={filterValuesHandler}
          />
          <select
            id="action"
            value={filterInputs.action || ''}
            onChange={filterValuesHandler}
          >
            <option value="">Select Action</option>
            <option value="created">Created</option>
            <option value="updated">Updated</option>
            <option value="deleted">Deleted</option>
          </select>
          <input
            id="date"
            type="date"
            value={filterInputs.date || ''}
            onChange={filterValuesHandler}
          />
          <input
            id="name"
            type="text"
            placeholder="Name..."
            value={filterInputs.name || ''}
            onChange={filterValuesHandler}
          />
        </>
      )}

      {selectedEventType === 'loginEvents' && (
        <>
          <input
            id="username"
            type="text"
            placeholder="Username..."
            value={filterInputs.username || ''}
            onChange={filterValuesHandler}
          />
          <input
            id="date"
            type="date"
            value={filterInputs.date || ''}
            onChange={filterValuesHandler}
          />
        </>
      )}

      <button className="ui button" onClick={clearFilters}>
        Clear Filters
      </button>
    </FilterRowCont>
  );
};

const FilterRowCont = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
`;
