import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Wrapper,
  StyledForm,
  StyledTable,
} from "../StyledComps/styledComponents";
import { BtnRedSm } from "../Components/Button";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
const Location = () => {
  const [locations, setLocations] = useState([]);
  const [newLocation, setNewLocation] = useState({ title: "", value: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);

  const fetchLocations = async () => {
    try {
      const response = await axios.get("/api/locations");
      setLocations(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching locations:", error);
      setIsLoading(false);
    }
  };
  const { username } = useSelector((state) => state.user);

  const handleInputChange = (e) => {
    setNewLocation({ ...newLocation, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Check if the title already exists in the locations list
    const titleExists = locations.some(
      (location) =>
        location.title.toLowerCase() === newLocation.title.toLowerCase()
    );
    if (titleExists) {
      console.error("Error: Location title already exists");
      setIsSubmitting(false);
      return; // Stop the submission process if title exists
    }

    try {
      await axios.post("/api/locations", newLocation);
      await fetchLocations();
      setNewLocation({ title: "", value: "" });
    } catch (error) {
      console.error("Error creating location:", error);
    }
    setIsSubmitting(false);
  };

  const handleDelete = async (id) => {
    setIsActionLoading(true); // Begin action loading
    try {
      await axios.delete(`/api/locations/${id}`);
      await fetchLocations();
    } catch (error) {
      console.error("Error deleting location:", error);
    }
    setIsActionLoading(false); // End action loading
  };
  const isDeleteVisible = username === "Donatas" || username === "admin";

  useEffect(() => {
    fetchLocations();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <h2>Locations</h2>
      {isSubmitting || isActionLoading ? (
        <div className="ui active inverted dimmer">
          <div className="ui text loader">Loading</div>
        </div>
      ) : null}
      <StyledForm onSubmit={handleSubmit}>
        <div
          className="ui input"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <input
            name="title"
            value={newLocation.title}
            onChange={handleInputChange}
            placeholder="Place"
            required
          />
        </div>
        <div
          className="ui input"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <input
            name="value"
            value={newLocation.value}
            onChange={handleInputChange}
            placeholder="Description"
            required
          />
        </div>
        <button
          type="submit"
          className="ui button"
          style={{ marginTop: "10px" }}
          disabled={!newLocation.title || !newLocation.value}
        >
          Add Location
        </button>
      </StyledForm>
      <form onSubmit={handleSubmit}></form>
      <StyledTable>
        <thead>
          <tr>
            <th>Place</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {locations.map((location) => (
            <tr key={location._id}>
              <td>{location.title}</td>
              <td>{location.value}</td>
              <td>
                {isDeleteVisible && (
                  <div onClick={() => handleDelete(location._id)}>
                    <BtnRedSm>
                      <MdDelete />
                    </BtnRedSm>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </Wrapper>
  );
};

export default Location;
