import { useState, useEffect } from 'react';

export const useFilterProducts = () => {
  const emptyFilterInputObj = {
    type: '',
    length: '',
    height: '',
    width: '',
    place: '',
    reserved: '',
    defect: '',
    amount: '',
    square: '',
  };

  // Load saved filter from localStorage or use default empty filters
  const savedFilterInputs =
    JSON.parse(localStorage.getItem('filterInputs')) || emptyFilterInputObj;

  // Control filter inputs
  const [filterInputs, setFilterInputs] = useState(savedFilterInputs);

  // Save filter inputs to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('filterInputs', JSON.stringify(filterInputs));
  }, [filterInputs]);

  const filterValuesHandler = (e) => {
    setFilterInputs({
      ...filterInputs,
      [e.target.id]: e.target.value,
    });
  };

  const filterSelectHandler = (e) => {
    const idx = e.target.selectedIndex;
    const el = e.target.childNodes[idx].value;
    setFilterInputs({
      ...filterInputs,
      [e.target.id]: el,
    });
  };

  const clearInputs = () => {
    setFilterInputs(emptyFilterInputObj);
    localStorage.removeItem('filterInputs'); // Clear filters from localStorage
  };

  return {
    filterInputs,
    setFilterInputs,
    filterValuesHandler,
    filterSelectHandler,
    clearInputs,
  };
};
