export const emptyInputObj = {
  product: {
    product_type: '',
    type_img: '',
  },
  product_img: '[]',
  warehouse_location: '',
  dimensions: {
    short: '',
    long: '',
    width: '',
  },
  defect: false,
  comments: '',
  edited_by: '',
  reserved: {
    isReserved: false,
    id: '',
  },
};
