import React from 'react';
import {
  ButtonsWrapper,
  ModalWrapper,
  StyledForm,
} from '../StyledComps/styledComponents';
import { BtnGreen, BtnRed } from '../Components/Button';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const EditEventModal = ({ eventToEdit, onInputChange, onSubmit, closeModalHandler }) => {
  const getEventTypeLabel = (type) => {
    const types = {
      0: 'Pajamavimas',
      1: 'Pardavimas',
      2: 'Nurašimas',
    };
    return types[type] || 'Unknown';
  };
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };
  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    onInputChange({ target: { value: formattedDate } }, 'date');
  };
  return (
    <ModalWrapper className="outer" onClick={closeModalHandler}>
      <div className="inner" onClick={(e) => e.stopPropagation()}>
        <h1>Edit Event</h1>
        <div className="line"></div>
        <StyledForm>
          <div className="form-row">
            <div className="form-control">
              <label htmlFor="type">Event Type</label>
              <input
                type="text"
                id="type"
                readOnly
                disabled
                value={getEventTypeLabel(eventToEdit.type)}
              />
            </div>
            <div className="form-control">
              <label htmlFor="date">Date</label>
              <DatePicker
                selected={eventToEdit.date ? new Date(eventToEdit.date) : null}
                onChange={handleDateChange}
                dateFormat="yyyy/MM/dd"
                placeholderText="yyyy/mm/dd"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-control">
              <label htmlFor="amount">Amount</label>
              <input
                type="number"
                id="amount"
                min="0"
                placeholder="Enter amount"
                value={eventToEdit.amount}
                onChange={(e) => onInputChange(e, 'amount')}
              />
            </div>
            <div className="form-control">
              <label htmlFor="reason">Reason</label>
              <input
                type="text"
                id="reason"
                placeholder="Enter reason for event"
                value={eventToEdit.reason}
                onChange={(e) => onInputChange(e, 'reason')}
              />
            </div>
            <div className="form-control">
              <label htmlFor="onePrice">One Price</label>
              <input
                type="text"
                id="onePrice"
                placeholder="Enter price per item"
                value={eventToEdit.onePrice}
                onChange={(e) => onInputChange(e, 'onePrice')}
              />
            </div>
          </div>

          <ButtonsWrapper>
            <BtnRed handler={closeModalHandler}>Close</BtnRed>
            <BtnGreen handler={onSubmit}>Update Event</BtnGreen>
          </ButtonsWrapper>
        </StyledForm>
      </div>
    </ModalWrapper>
  );
};
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

export default EditEventModal;
